.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  position: fixed;
  bottom: 0px;
  padding-bottom: 5px;
  padding-top: 5px;
  width: 100%;
  background-color: #fff;
}

.Atelier-icon {
  height: 40px;
}

.Deustotech-icon {
  height: 24px;
  margin-left: 30px;
}

.You-decide-brand {
  font-family: "FingerPaint-Regular";
  font-size: 24px;
}

.Brand-div {
  display: inline-block;
}

.Brand-icon-left {
  height: 40px;
  margin-right: 20px;
}

.Brand-icon-right {
  height: 40px;
  margin-left: 0px;
  padding-bottom: 5px;
}

.Atelier-info {
  margin-top: 100px;
}

.Atelier-info-text {
  margin-bottom: 30px;
}

.App-form-container-div {
  margin-top: 50px;
  text-align: -webkit-center;
}

.App-form-div {
  max-width: 1320px;
  text-align: left;
  padding-bottom: 80px;
}

.Events-actions-div {
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
}

.Icon-button {  
  padding-bottom: 5px !important;
  padding-top: 3px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.Main-screen {
  padding-top: 50px;
}

.Scope-img {
  height: 48px !important;
  display: inline-block;
}

.Score-input {
  display: inline-block;  
}

.Form-label {
  font-weight: bold;
}

.From-select {
  height: 48px;
  width: 200px;
}

.Language-select-div{  
  display: inline-block;
  margin-left: 20px;
}

.Language-select{
  font-size: 14px;
  border-style: hidden;
  border-color: #fff;
  width: 45px;
  font-family: 'Roboto-Regular';
  color: #1A79BD;
  text-align: center;
  background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
